import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const Soon = () => (
		<div className="min-h-screen pt-16 pb-12 flex flex-col bg-white">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center">
          <Link to="/" className="inline-flex">
            <span className="sr-only">FastProfit</span>
            <StaticImage
              className="h-48 w-auto rounded-full"
              src='../images/raccoon-000-1920-square.jpg'
              alt="Soon"
              placeholder="blurred"
              width={200}
              height={200}
            />
          </Link>
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">Привет!</p>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Скоро здесь всё будет.</h1>
            <p className="mt-2 text-base text-gray-500">Всё самое важное уже на главной странице и других разделах сайта.</p>
            <div className="mt-6">
              <Link to="/" className="text-base font-medium text-indigo-600 hover:text-indigo-500">
                На главную<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
		</div>
)

export default Soon