import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Soon from '../components/soon'

const HelpCenter = () => (
	<Layout>
		<Seo title='Help Center' />
		<Soon />
	</Layout>
)

export default HelpCenter